.title {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}

.receipt {
  overflow: auto;
  height: 408px;
  box-shadow: 0 0 1px;
  border-radius: 14px;
  margin-bottom: 16px;
}

object {
  width: 100%;
}

.buttons {
  text-align: center;
}
