.contacts {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;

  li {
    padding: 0 12px;

    a {
      border: 1px solid #0090ff;
      border-radius: 14px;
      padding: 13px;
      display: block;

      &.whatsapp {
        border-color: #25d366;
      }

      img {
        display: block;
      }
    }
  }
}
