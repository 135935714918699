.signPage {
  label {
    color: #76858f;
    font-size: 13px;
    line-height: 16px;
    display: inline-flex;
    margin-bottom: 3px;
  }

  button {
    margin-bottom: 16px;
  }
}

.draw {
  border: 1px solid #66bdff;
  border-radius: 12px;
  overflow: hidden;

  &.error {
    border-color: #eb5757;
  }
}
