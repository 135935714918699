body {
  background: #fff;
}

.App {
  // font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #5f6d76;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e2e9f0;
}

.container {
  padding: 32px 16px 24px;
}

a {
  color: #0090ff;
}

.sign {
  width: 288px;
  margin: 0 auto 92px;
}

@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: spin infinite 20s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
