.socials {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;

  li {
    padding: 0 12px;

    a {
      border: 1px solid #cfdae4;
      border-radius: 14px;
      padding: 9px;
      display: block;

      img {
        display: block;
      }
    }
  }
}
