.failed {
  position: relative;
  overflow: hidden;
}

.top {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: linear-gradient(179.8deg, #5ab8ff 0.18%, #cfeaff 99.82%);
  color: #fff;

  > div {
    height: 80vh;
    box-sizing: border-box;
    padding-top: 56px;
    background: no-repeat url('../../assets/exclamation.svg') center 113px,
      no-repeat url('../../assets/clouds.svg') left top;
    background-size: auto, cover;
  }

  strong {
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
  }
}

.main {
  background: #ffffff;
  box-shadow: 0px -2px 6px rgba(0, 46, 79, 0.15);
  border-radius: 20px 20px 0px 0px;
  position: relative;
  margin-top: calc(100vh - 232px);
  margin-top: 313px;
  padding: 32px 40px;
  text-align: center;

  .main_title {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #252627;
    margin-bottom: 16px;
  }

  .main_sub_title {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #252627;
    margin-bottom: 16px;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    color: #5f6d76;
    margin-bottom: 24px;
  }
}
